<template>
  <div class="mt-n2 chart-section">
    <div class="floating-select-field mb-2 d-flex align-items-center justify-content-between">
      <b-form-select
        id="myChart"
        class="form-control"
        v-model="selectedChart"
        @change="onChangeChart"
        @input="selectedChart = $event"
        :class="{
          'is-value-exist': selectedChart,
        }"
      >
        <b-form-select-option v-for="(chart, index) in chartContent" :key="index" :value="chart">
          {{ chart.chartTitle }}<template v-if="chart.detail">{{ ` (${chart.detail})` }}</template>
        </b-form-select-option>
      </b-form-select>
      <label for="myChart">Select Chart</label>
      <InfoButtonTogglePopover v-if="selectedChart && selectedChart.description" class="ml-2" :description="selectedChart.description" />
    </div>
    <template v-if="selectedChart">
      <p class="text-center theme-font-bold mb-0" v-if="selectedChart.subTitle">
        {{ selectedChart.subTitle }}
      </p>
      <div :class="{ 'no-chart-Data-wrapper': !this.selectedChart.chartData }">
        <component
          :is="selectedChart.chartType"
          :styles="chartStyles"
          :chartData="this.selectedChart.chartData"
          :colorsList="selectedChart.colorSchemeLst"
        ></component>
      </div>
      <p class="theme-font-bold no-chart-data" v-if="!selectedChart.chartData">No data available</p>
    </template>
  </div>
</template>
<script>
import InfoButtonTogglePopover from '../common/InfoButtonTogglePopover.vue'
export default {
  name: 'Charts',
  components: {
    InfoButtonTogglePopover,
    BarChart: () => import('./BarChart.vue'),
    HorizontalBarChart: () => import('./HorizontalBarChart.vue'),
    LineChart: () => import('./LineChart.vue'),
    PieChart: () => import('./PieChart.vue'),
    DoughnutChart: () => import('./DoughnutChart.vue'),
  },
  data() {
    return {
      selectedChart: null,
    }
  },
  props: ['chartContent'],
  computed: {
    chartStyles() {
      return {
        height: '200px',
        position: 'relative',
      }
    },
  },
  mounted() {
    this.selectedChart = this.chartContent.length ? this.chartContent[0] : null
  },
  methods: {
    onChangeChart(chart) {
      this.selectedChart = chart
    },
  },
}
</script>
<style lang="scss" scoped>
.chart-section {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}
.no-chart-data {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  font-size: 20px;
}
::v-deep .no-chart-Data-wrapper {
  .chartjs-render-monitor {
    opacity: 0.4;
  }
}
</style>
